import { Box, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';


const TEST_ENV_PASSWORD = 'nissan4life';

const PasswordComponent: React.FC<{ onPasswordCorrect: () => void }> = ({ onPasswordCorrect }) => {
    const [input, setInput] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (input === TEST_ENV_PASSWORD) {
            sessionStorage.setItem('isAuthenticated', 'true');
            onPasswordCorrect();
        } else {
            setError('Incorrect password.');
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', gap: 3, p: 3 }}>
            <Typography variant='h6' align='center'>Dit is de test-omgeving van Carmeeter.com. Voer het wachtwoord in om verder te gaan.</Typography>
            <TextField
                type='password'
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder='Wachtwoord'
                sx={{ width: '240px' }}
            />
            <Button variant='contained' color='primary' onClick={handleSubmit} sx={{ width: '240px' }}>Ga verder</Button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </Box>
    );
};

export default PasswordComponent;